import SendIcon from "@/core/ui/iconsax/linear/arrow-right.svg"
import StopIcon from "@/core/ui/iconsax/linear/pause.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton, DiscoInput, DiscoInputProps } from "@disco-ui"
import { lighten, useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useState } from "react"

interface BrainSearchInputProps
  extends TestIDProps,
    Pick<DiscoInputProps, "disabled" | "multiline" | "placeholder"> {
  onSubmit: (input: string) => void
  onChange?: (input: string) => void
  onAbort?: () => void
  isGenerating?: boolean
  clearOnSubmit?: boolean
  classes?: {
    root?: string
  }
}

function BrainSearchInput({
  placeholder = "Ask a question...",
  onSubmit,
  onChange,
  onAbort,
  isGenerating = false,
  classes: customClasses,
  multiline = true,
  clearOnSubmit = true,
}: BrainSearchInputProps) {
  const [input, setInput] = useState("")
  const isMobile = useIsMobile()

  const theme = useTheme()
  const classes = useStyles()

  const noPrompt = input.trim().length === 0

  return (
    <DiscoInput
      placeholder={placeholder}
      classes={{
        root: classNames(classes.inputRoot, customClasses?.root),
        input: classes.input,
      }}
      value={input}
      onChange={handleChange}
      multiline={multiline}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={!isMobile}
      endAdornment={
        isGenerating ? (
          <DiscoIconButton
            className={classes.sendButton}
            tooltip={"Stop Generating"}
            onClick={onAbort}
          >
            <StopIcon className={classes.stopIcon} />
          </DiscoIconButton>
        ) : (
          <DiscoIconButton
            className={classes.sendButton}
            disabled={noPrompt}
            haveDisabledStyles
            variant={"primary"}
            onClick={(e) => handleSubmit(e)}
          >
            <SendIcon className={classes.sendIcon} color={theme.palette.common.white} />
          </DiscoIconButton>
        )
      }
      onKeyPress={(e) => {
        if (noPrompt) return

        if (e.key === "Enter" && !e.shiftKey) {
          handleSubmit(e)
        }
      }}
    />
  )

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
    onChange?.(e.target.value)
  }

  function handleSubmit(
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) {
    onSubmit(input)
    e.preventDefault()
    if (clearOnSubmit) setInput("")
  }
}

const useStyles = makeUseStyles((theme) => ({
  inputRoot: {
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    width: "100%",
    padding: theme.spacing(1),
    minHeight: "48px",
  },
  input: {
    padding: theme.spacing(1),
    maxHeight: "600px",
    overflowY: "auto !important",
  },
  sendButton: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    height: "32px",
    width: "32px",
    alignSelf: "flex-end",
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  sendIcon: {
    transform: "rotate(-90deg)",
  },
  stopIcon: {
    color: `${theme.palette.common.white} !important`,
  },
}))

export default BrainSearchInput
